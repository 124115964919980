import React from 'react'
import { useKeyFeatureImages } from '../../graphql/useKeyFeatureImagesHook'

import KeyFeatures from '../KeyFeatures'
import KeyFeatureBox from '../KeyFeatures/KeyFeatureBox'

const HostelFeatures = ({ data }) => {
  const {
    DashboardImgOne,
    DashboardImgTwo,
    ChannelManagerImgOne,
    ChannelManagerImgTwo,
    BookingEngineImgOne,
    BookingEngineImgTwo,
    CalendarImgOne,
    CalendarImgTwo,
    EmailImgOne,
    EmailImgTwo,
    MobileAppScanner,
    MobileAppScannerFront
  } = useKeyFeatureImages()

  const {
    key_features: { features }
  } = data

  return (
    <KeyFeatures data={data}>
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '0',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '-30',
            max: '30',
            speed: '-2',
            axis: 'y'
          }
        }}
        parallaxClass="dashboard"
        direction="row"
        title={features[0].title}
        text={features[0].description}
        imageOne={DashboardImgOne.childImageSharp.fluid}
        imageTwo={DashboardImgTwo.childImageSharp.fluid}
        top="33"
        left="-8"
        deg="270"
      />
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '0',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '0',
            max: '50',
            speed: '-3',
            axis: 'y'
          }
        }}
        parallaxClass="channel-manager"
        direction="row-reverse"
        title={features[1].title}
        text={features[1].description}
        imageOne={ChannelManagerImgOne.childImageSharp.fluid}
        imageTwo={ChannelManagerImgTwo.childImageSharp.fluid}
        top="20"
        left="50"
        deg="-270"
        width="300"
        noGradient
      />
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '0',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '-30',
            max: '100',
            speed: '-2',
            axis: 'y'
          }
        }}
        parallaxClass="calendar"
        direction="row"
        title={features[2].title}
        text={features[2].description}
        imageOne={CalendarImgOne.childImageSharp.fluid}
        imageTwo={CalendarImgTwo.childImageSharp.fluid}
        top="45"
        left="-5"
        deg="270"
        width="300"
        noGradient
      />
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '0',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '10',
            max: '30',
            speed: '-3',
            axis: 'y'
          }
        }}
        parallaxClass="booking-engine"
        direction="row-reverse"
        title={features[3].title}
        text={features[3].description}
        imageOne={BookingEngineImgOne.childImageSharp.fluid}
        imageTwo={BookingEngineImgTwo.childImageSharp.fluid}
        top="45"
        left="32"
        deg="-270"
        width="470"
        noGradient
        beActive
        beLink="https://chameleon.book.rentl.io/"
        origin="hostel"
      />
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '0',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '-30',
            max: '30',
            speed: '-3',
            axis: 'y'
          }
        }}
        parallaxClass="email"
        direction="row"
        title={features[4].title}
        text={features[4].description}
        imageOne={EmailImgOne.childImageSharp.fluid}
        imageTwo={EmailImgTwo.childImageSharp.fluid}
        top="19"
        left="-6"
        deg="270"
        width="500"
        noGradient
      />
      <KeyFeatureBox
        parallaxParams={{
          imageOne: {
            min: '-200',
            max: '100',
            speed: '1',
            axis: 'y'
          },
          imageTwo: {
            min: '-60',
            max: '0',
            speed: '-3',
            axis: 'y'
          }
        }}
        parallaxClass="mobile-app"
        direction="row-reverse"
        title={features[5].title}
        text={features[5].description}
        imageOne={MobileAppScanner.childImageSharp.fluid}
        imageTwo={MobileAppScannerFront.childImageSharp.fluid}
        widthImageOne="375"
        imageOneTop="35"
        top="14"
        left="35"
        deg="-270"
        width="300"
        noGradient
      />
    </KeyFeatures>
  )
}

export default HostelFeatures
